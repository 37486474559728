body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* 컴포넌트 파일 상단에 추가하거나 별도의 CSS 파일로 관리 */
.custom-quill-editor {
  min-height: 500px;
}

.custom-quill-editor .ql-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.custom-quill-editor .ql-editor {
  flex: 1;
  min-height: 500px;
  overflow-y: auto;
}